import React, { useState } from "react"
import styled from "styled-components"
import Reaptcha from "reaptcha"

import { isEmailValid, urlEncodeJsonObject } from "../../lib/utils"

import FormInputField from "../reusable-components/form-input-field"
import SubmitButton from "../reusable-components/buttons/submit-button"

const ContactForm = ({
  contactTitle,
  contactSubtitle,
  formName, //required
  className = "", //optional
}) => {
  const [captchaPassed, setCaptchaPassed] = useState(false)
  const [submitError, setSubmitError] = useState("")
  const [nameValue, setNameValue] = useState({ id: "", value: "" })
  const [surnameValue, setSurnameValue] = useState({ id: "", value: "" })
  const [emailValue, setEmailValue] = useState({ id: "", value: "" })
  const [phoneValue, setPhoneValue] = useState({ id: "", value: "" })
  const [messageValue, setMessageValue] = useState({ id: "", value: "" })
  const [submitMessage, setSubmitMessage] = useState("")

  
 
  const handleSubmit = (event) => {
    event.preventDefault()
  
    if (!isEmailValid(emailValue.value)) {
      setSubmitError("Invalid email")
      return
    }
  
    if (!captchaPassed) {
      setSubmitError("Please complete the reCaptcha checkbox before continuing")
      return
    }
  
    const dataObject = { "form-name": formName }
  
    dataObject[`${formName}-name`] = nameValue.value
    dataObject[`${formName}-surname`] = surnameValue.value
    dataObject[`${formName}-email`] = emailValue.value
    dataObject[`${formName}-phone`] = phoneValue.value
    dataObject[`${formName}-message`] = messageValue.value
  
    console.log("Form data object:", dataObject);
    const bodyData = urlEncodeJsonObject(dataObject);
    console.log("Encoded body data:", bodyData);
  
    fetch("https://backend.eurobath.co.za/wp-content/themes/eurobath-gatsby-theme/contact-handler.php", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: bodyData,
    })
      .then(response => {
        console.log("Raw response:", response);
        return response.json();
      })
      .then(data => {
        console.log("Parsed response data:", data);
        if (data.status === 'success') {
          setSubmitMessage(
            "Thank you! Your message has been submitted and we look forward to replying soon."
          )
          setSubmitError("")
        } else {
          throw new Error(data.message || "Form submission failed")
        }
      })
      .catch((err) => {
        console.error("Form submission failed", err)
        setSubmitError("Form submission failed")
        setSubmitMessage("")
      })
  }

  return (
    <FormStyling className={className}>
      {!contactTitle ? null : (
        <ContactFormSectionStyling>
          <h2 dangerouslySetInnerHTML={{ __html: contactTitle }} />
          {!contactSubtitle ? null : (
            <h3 dangerouslySetInnerHTML={{ __html: contactSubtitle }} />
          )}
          {submitMessage ? (
            <FormSubmitMessageStyling>{submitMessage}</FormSubmitMessageStyling>
          ) : (
            <form
            method="post"
            name={formName}
            onSubmit={handleSubmit}
           >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value={formName} />
              <FormInputField
                className="half-width-input"
                labelName="Name"
                fieldId={`${formName}-name`}
                inputValue={nameValue.value}
                onValueChange={(value) => {
                  setNameValue(value)
                }}
              />
              <FormInputField
                className="half-width-input"
                labelName="Surname"
                fieldId={`${formName}-surname`}
                inputValue={surnameValue.value}
                onValueChange={(value) => {
                  setSurnameValue(value)
                }}
              />
              <FormInputField
                className="half-width-input"
                labelName="Email Address"
                fieldType="email"
                fieldId={`${formName}-email`}
                inputValue={emailValue.value}
                onValueChange={(value) => {
                  setEmailValue(value)
                }}
              />
              <FormInputField
                labelName="Phone Number"
                className="half-width-input"
                fieldType="phone"
                fieldId={`${formName}-phone`}
                required={false}
                inputValue={phoneValue.value}
                onValueChange={(value) => {
                  setPhoneValue(value)
                }}
              />
              <FormInputField
                labelName="Message"
                fieldType="textarea"
                fieldId={`${formName}-message`}
                inputValue={messageValue.value}
                onValueChange={(value) => {
                  setMessageValue(value)
                }}
              />
              <StyledReaptcha
                sitekey={process.env.GATSBY_GOOGLE_CAPTCHA_SITEKEY}
                onVerify={() => {
                  setCaptchaPassed(true)
                }}
                size="normal"
              />
              {submitError && (
                <FormSubmitErrorMessageStyling style={{ color: "red" }}>
                  {submitError}
                </FormSubmitErrorMessageStyling>
              )}
              <div>
                <SubmitButton text="Submit" />
              </div>
            </form>
          )}
        </ContactFormSectionStyling>
      )}
    </FormStyling>
  )
}

export default ContactForm

// ===============
//     STYLES
// ===============
const FormStyling = styled.div`
  margin: 0 auto;
  max-width: 820px;
  width: 100%;
`
const ContactFormSectionStyling = styled.div`
  max-width: 820px;
  width: 100%;
  margin: auto;
  .half-width-input input {
    display: block;
  }
  label {
    width: 100%;
    display: inline-block;
    margin-top: 30px;
  }
  .half-width-input {
    max-width: 400px;
    width: 100%;
    display: inline-block;
  }
  & form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  & form > div button {
    margin-top: 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    margin: 6rem auto 0;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .half-width-input {
      width: 48%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .half-width-input {
      width: 100%;
      max-width: 100%;
    }
    & form > div {
      width: 100%;
      max-width: 100%;
    }
    & form > div button {
      width: 100%;
    }
  }
`
const FormSubmitMessageStyling = styled.div`
  padding: 4rem 0;
  font-size: 18px;
`
const StyledReaptcha = styled(Reaptcha)`
  width: 100%;
`
const FormSubmitErrorMessageStyling = styled.div`
  padding-top: 10px;
  font-size: 18px;
  width: 100%;
`
